import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { monochrome } from '@vyce/core/src/theme/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    summaryRoot: {
      minHeight: '24px !important',
    },
    summaryContent: {
      margin: '4px 0 !important',
    },
    expandIcon: {
      padding: '2px',
    },
    errorText: {
      color: theme.palette.error.main,
      lineHeight: '24px',
      marginTop: '1px',
    },
    checkboxLabel: {
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '24px',
      color: theme.palette.type === 'dark' ? monochrome.light : monochrome.dark,
    },
    disabledLabel: {
      opacity: 0.5,
    },
    activateModuleText: {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      fontWeight: 600,
      fontSize: '15px',
      cursor: 'pointer',
    },
    detailsTitle: {
      color: theme.palette.type === 'dark' ? monochrome.light : monochrome.dark,
      fontSize: '15px',
      fontWeight: 400,
    },
  })
);
