import React, { useContext, useState, useEffect } from 'react';

import { v4 as uuid } from 'uuid';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { FiChevronDown } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';
import isEqual from 'lodash/isEqual';

import { AutocompleteProps } from '../../types';
import { AppTextField } from '../inputs';

import { AppChip } from '../AppChip';
import { DeviceContext } from '../../contexts';

const filter = createFilterOptions<any>();

export const appSmallInputStyles = {
  padding: '2px 24px 6px 0px !important',
  maxHeight: '41px !important',
};

export const appAutocompleteUseStyles = makeStyles<Theme, { withoutLabel?: boolean }>(theme =>
  createStyles({
    inputRoot: props => (props.withoutLabel ? appSmallInputStyles : {}),
    input: {
      padding: '8px 8px 8px 15px !important',
      marginTop: 0,
    },
    tag: {
      display: 'none',
    },
    option: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
        minHeight: '40px',
      },
    },
    popupIndicator: {
      padding: theme.spacing(1),
    },
    clearIndicator: {
      padding: theme.spacing(1),
    },
  })
);

export const ControlledAutocomplete: React.FC<AutocompleteProps> = ({
  name,
  items = [],
  multiple,
  label,
  rules,
  disabled = false,
  disableClearable,
  defaultValue,
  margin,
  onChange,
}) => {
  const classes = appAutocompleteUseStyles({ withoutLabel: !label });
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const { isMobile } = useContext(DeviceContext);
  const [key, setKey] = useState(uuid());

  const value = watch(name);

  const errorMessage = errors[name] && errors[name]?.message;

  const deleteItem = (field: any, item: any) => {
    const updatedData = (field.value as Array<any>).filter(value => !isEqual(value, item));
    field.onChange(updatedData);
  };

  const setInputValue = (newInputValue: string) => {
    onChange && onChange(newInputValue);
  };

  useEffect(() => {
    if (!value) {
      setKey(uuid());
    }
  }, [value]);

  return (
    <Controller
      render={({ field }: any) => (
        <div className="multiple-autocomplete">
          <Autocomplete
            value={field.value}
            key={key}
            onChange={(event, value) => field.onChange(value)}
            multiple={multiple}
            fullWidth
            popupIcon={<FiChevronDown size="25px" color="#A0A3BD" />}
            closeIcon={<IoClose size="25px" color="#A0A3BD" />}
            disabled={disabled}
            defaultValue={defaultValue}
            disableClearable={disableClearable}
            disablePortal={isMobile}
            id={name}
            classes={classes}
            options={items}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            getOptionLabel={option => option.name || option}
            filterOptions={(options, params) => filter(options, params)}
            renderInput={params => {
              return (
                <AppTextField
                  {...params}
                  error={errors && !!errorMessage}
                  margin={margin}
                  autoComplete="off"
                  label={label}
                  helperText={((errors && errorMessage) as string) || ''}
                />
              );
            }}
          />
          {multiple && (
            <Box marginTop="12px">
              {(field.value as Array<any>) &&
                (field.value as Array<any>).map((item, index) => (
                  <AppChip
                    style={{ margin: '0 6px 6px 0' }}
                    key={index}
                    label={item.name || item}
                    onDelete={() => deleteItem(field, item)}
                  />
                ))}
            </Box>
          )}
        </div>
      )}
      control={control}
      name={name}
      rules={rules}
    />
  );
};
