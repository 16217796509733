import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  selector: {
    borderRadius: 6,
    '& .MuiInputBase-input': {
      paddingTop: 10,
    },
  },
  count: {
    fontWeight: 600,
    textWrap: 'nowrap',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
}));
