import {
  MenuItem,
  Divider,
  InputLabel,
  SelectProps,
  FormControl,
  FormHelperText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';

import { AppSelect } from './AppSelect';

type Props = {
  groupOneOptions: { name: string; value: string }[];
  groupTwoOptions: { name: string; value: string }[];
  name: string;
  label?: string;
  disabled?: boolean;
} & SelectProps;

export const useStyles = makeStyles<Theme, { withoutLabel?: boolean }>(theme => ({
  selector: {
    borderRadius: 16,
    '& .MuiInputBase-input': {
      paddingTop: props => (props.withoutLabel ? 10 : 27),
    },
  },
}));

export const AppGroupedSelect = ({
  groupOneOptions,
  groupTwoOptions,
  name,
  disabled,
  label,
  ...restProps
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const classes = useStyles({ withoutLabel: !label });

  const errorMessage = errors[name] && errors[name]?.message;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControl fullWidth variant="filled" disabled={disabled}>
          <InputLabel id="app-select-label" error={!!errorMessage}>
            {label}
          </InputLabel>
          <AppSelect
            labelId="app-select-label"
            id={name}
            fullWidth
            {...field}
            {...restProps}
            className={classes.selector}>
            {groupOneOptions.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
            <Divider style={{ height: '4px' }} />
            {groupTwoOptions.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </AppSelect>
          <FormHelperText error id="select-helper-text">
            {(errorMessage as string) || ''}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};
