import { MappingItem, RadioDataItem, TabItem, Tag } from '../types';

export const SEARCH_STATUSES: Tag[] = [
  { group: 'available_now', name: 'Available immediately', uuid: '3' },
  { group: 'active', name: 'Just looking', uuid: '2' },
  { group: 'inactive', name: 'Not available', uuid: '1' },
];

export enum DOCUMENT_STATUSES {
  PENDING = 'Pending',
  UNVERIFIED = 'Unverified',
  MORE_REQUESTED = 'More requested',
  VERIFIED = 'Vyce verified',
  EXPIRING = 'Expiring',
  EXPIRED = 'Expired',
  DECLINED = 'Declined',
}

export enum RTW_VALUES {
  LIMITED = 'limited',
  INDEFINITE = 'indefinite',
  NO_RTW = 'no_rtw',
  REJECTED = 'rejected',
}

export const DOCUMENT_STATUSES_ARR = [
  DOCUMENT_STATUSES.PENDING,
  DOCUMENT_STATUSES.UNVERIFIED,
  DOCUMENT_STATUSES.MORE_REQUESTED,
  DOCUMENT_STATUSES.VERIFIED,
  DOCUMENT_STATUSES.EXPIRING,
  DOCUMENT_STATUSES.EXPIRED,
  DOCUMENT_STATUSES.DECLINED,
];

export const BOOK_TIME_LINK = 'https://meetings.hubspot.com/alangosnell/intro-meeting-with-alan-vyce';
export const VYCE_PHONE = '0203 868 6303';
export const VYCE_EMAIL = 'hello@vyce.io';

export const DOC_TYPES = [
  {
    value: 'driving_license',
    title: 'Driving License',
  },
  {
    value: 'birth_certificate',
    title: 'Birth Certificate',
  },
  {
    value: 'regula_id_check',
    title: 'Regula ID Check',
  },
  {
    value: 'other',
    title: 'Other',
  },
];

export const GRID_PAGE_SIZE = 20;
export const TABLE_OFFSET_DELAY = 300;

export const LIST_STEP = 9;

export enum SUBCONTRACTOR_TYPES {
  CIS_SELF_EMPLOYED = 'cis',
  SELF_EMPLOYED = 'self-employed',
  PAYE = 'paye',
}

export const CIS_SELF_EMPLOYED_LABEL = 'As CIS Self Employed Contractors (construction companies only)';
export const SELF_EMPLOYED_LABEL = 'As Self Employed Contractors (not construction)';

export const LEGEND_PAYROLL_TABS: TabItem[] = [
  {
    label: 'Timesheets',
    link: '/payroll/timesheets',
  },
  {
    label: 'Payruns',
    link: '/payroll/payruns',
  },
  {
    label: 'Reports',
    link: '/payroll/reports',
  },
  {
    label: 'Users',
    link: '/payroll/users',
  },
  {
    label: 'Pay Schedules',
    link: '/payroll/schedules',
  },
];

export enum HMRC_SETTINGS_TYPE {
  OUTSOURCE = 'outsource',
  COMPANY_SETTINGS = 'company-settings',
}

export const DEFAULT_EMAIL_NOTIFICATION_DATE = new Date(2022, 0, 1, 12, 0, 0, 0);
export const DEFAULT_SMS_NOTIFICATION_DATE = new Date(2022, 0, 1, 8, 0, 0, 0);

export const PAYMENT_TIME_SETTINGS = [
  {
    label: 'Email Pay Statement',
    field: 'email_pay_statement',
    weekDayField: 'emailWeekday',
    defaultValue: DEFAULT_EMAIL_NOTIFICATION_DATE,
  },
  {
    label: 'SMS Net Payment',
    field: 'sms_pay_statement',
    weekDayField: 'SMSWeekday',
    defaultValue: DEFAULT_SMS_NOTIFICATION_DATE,
  },
];

export const PAYMENT_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

export const MONTH_PAYMENT_DAYS: RadioDataItem[] = [
  { label: 'Last working day of the month', value: '1' },
  { label: '28th of every month', value: '' },
];

export const PAY_FREQUENCIES: RadioDataItem[] = [
  { label: 'Weekly', value: 'weekly' },
  { label: 'Bi Weekly', value: 'two-weekly' },
  { label: 'Monthly', value: 'monthly' },
];

export const MAPPING_PROPERTIES: MappingItem[] = [
  { value: 'worker_reference', label: 'Worker Reference Number', required: true },
  { value: 'basic_units', label: 'Hours / Days', required: true },
  { value: 'basic_rate', label: 'Rate', required: true },
  { value: 'overtime_units', label: 'Overtime Hours', required: false },
  { value: 'overtime_rate', label: 'Overtime Rate', required: false },
  { value: 'adjustments', label: 'Adjustments', required: false },
  { value: 'expenses', label: 'Expenses', required: false },
  { value: 'miles', label: 'Miles', required: false },
];

export enum TAXATION_STATUSES {
  CIS20 = 'cis_20',
  CIS30 = 'cis_30',
  CIS_GROSS = 'cis_gross',
  SELF_EMPLOYED_GROSS = 'self_employed_gross',
  GROSS = 'gross',
}

export enum BUSINESS_TYPES {
  COMPANY = 'company',
  SOLE_TRADER = 'sole-trader',
}

export enum CERTIFICATES {
  CPCS = 'cpcs',
  EUSR = 'eusr',
  CSCS = 'cscs',
}

export const CERTIFICATE_FILTERS = [
  { name: 'CPCS Verified', type: CERTIFICATES.CPCS, status: true },
  { name: 'CPCS Unverified', type: CERTIFICATES.CPCS, status: false },
  { name: 'EUSR Verified', type: CERTIFICATES.EUSR, status: true },
  { name: 'EUSR Unverified', type: CERTIFICATES.EUSR, status: false },
  { name: 'CSCS Verified', type: CERTIFICATES.CSCS, status: true },
  { name: 'CSCS Unverified', type: CERTIFICATES.CSCS, status: false },
];

export const PAYROLL_FEE = 15;

export const MIN_WORKER_AGE = 16;
export const MAX_WORKER_AGE = 80;

export const AGE_MARKS_OPTIONS = [
  {
    value: 16,
    label: '16',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 35,
    label: '35',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 45,
    label: '45',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 55,
    label: '55',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 65,
    label: '65',
  },
  {
    value: 70,
    label: '70',
  },
  {
    value: 75,
    label: '75',
  },
  {
    value: 80,
    label: '80',
  },
];

export const MAIN_CONTAINER_ID = 'main-container-id';
export const SENDER_TYPES = ['employer', 'agent', 'bureau', 'other'];

export const QUALIFICATION_STATUSES = ['Valid', 'Expiring', 'Expired'];
