import { makeStyles, Theme } from '@material-ui/core';
import { monochrome } from '../../../theme/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  selector: {
    height: '100%',
    '& .MuiInputBase-input': {
      paddingTop: 10,
    },
  },
  circle: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
  },
  primaryBorder: {
    backgroundColor: theme.palette.primary.main,
  },
  warningBorder: {
    backgroundColor: theme.palette.warning.main,
  },
  neutralBorder: {
    backgroundColor: monochrome.mediumlight,
  },
  selectInput: {
    '& .MuiMenuItem-root': {
      borderBottom: 'unset',
    },
  },
}));
