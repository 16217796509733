import { createContext } from 'react';

import { WidgetItem, Team, LocationAndTeamsFilterState } from '@vyce/core/src/types';

interface Context {
  widget: WidgetItem;
  redirectUrl?: string;
  access_token: string;
  teams: Team[];
  selectedCompanyId: string;
  mainDashboardFilters?: LocationAndTeamsFilterState;
}

export const WidgetModuleContext = createContext({} as Context);

export const WidgetModuleProvider = WidgetModuleContext.Provider;
