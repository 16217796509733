import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Variant } from '@material-ui/core/styles/createTypography';
import { Theme } from '@material-ui/core/styles';

import { useBooleanState } from '@vyce/core/src/hooks';

const useStyles = makeStyles<Theme, { noPadding: boolean }>(() =>
  createStyles({
    accordionSummary: {
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: 0,
      },
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    },
    mainAccordionWrapper: {
      padding: props => (props.noPadding ? '0px' : '12px 16px'),
      '& .MuiAccordionSummary-root.Mui-expanded': {
        margin: 0,
      },
    },
    expanded: {
      margin: `0 !important`,
    },
  })
);

export const AppAccordion = <T,>({
  title,
  titleVariant = 'subtitle2',
  noPadding = false,
  extraSummaryText = '',
  renderDetailsBlock,
  ...restProps
}: {
  title: string;
  noPadding?: boolean;
  titleVariant?: Variant;
  extraSummaryText?: string;
  renderDetailsBlock: () => React.ReactNode;
} & (
  | {
      outsideControl: true;
      outsideExpanded: boolean;
      panel: T;
      onOutsideChange: (panel: T) => void;
    }
  | { outsideControl?: false }
)) => {
  const [expanded, setExpandedTrue, setExpandedFalse, toggleExpanded] = useBooleanState(false);
  const classes = useStyles({ noPadding });

  const handleChangeExpand = () => {
    if (restProps.outsideControl) {
      restProps.onOutsideChange(restProps.panel);
    } else {
      toggleExpanded();
    }
  };

  return (
    <Accordion
      expanded={restProps.outsideControl ? restProps.outsideExpanded : expanded}
      classes={{ expanded: classes.expanded }}
      className={classes.mainAccordionWrapper}>
      <AccordionSummary classes={{ root: classes.accordionSummary }} onClick={handleChangeExpand}>
        <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" gridGap={16}>
          <Typography variant={titleVariant}>{title}</Typography>

          <Box display="flex" alignItems="center" gridGap={8}>
            {extraSummaryText && (
              <Typography variant="caption" color="textSecondary">
                {extraSummaryText}
              </Typography>
            )}
            <Box display="flex" alignItems="center" justifyContent="center" paddingRight="10px">
              {expanded ? (
                <FiChevronUp size={20} color="#A0A3BD" />
              ) : (
                <FiChevronDown size={20} color="#A0A3BD" />
              )}
            </Box>
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails>{renderDetailsBlock()}</AccordionDetails>
    </Accordion>
  );
};
