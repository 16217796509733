export enum ENVIRONMENTS {
  DEV = 'dev',
  PROD = 'prod',
}

const NOJS_SCRAPERS_PASSWORD = process.env.REACT_APP_NOJS_SCRAPERS_PASSWORD;
const NOJS_SCRAPERS_USER = process.env.REACT_APP_NOJS_SCRAPERS_USER;
const NOJS_SCRAPERS_API_URL = process.env.REACT_APP_NOJS_SCRAPERS_API_URL;
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || ENVIRONMENTS.DEV;
const DEV_API_URL = 'https://api-dev.vyce.io/api/v1';
const DEV_TIME_API_URL = 'https://time-api-dev.vyce.io/public/v1';
const RECAPTCHA_TEST_SITE_KEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
export const CAPTCHA_AVOIDER = 'd3dnT52VE5D_EvAJ6lIiNyS35ZpY45nGoIcXyqJOyh4';
const API_URL = process.env.REACT_APP_API_URL || DEV_API_URL;
const TIME_API_URL = process.env.REACT_APP_TIME_API_URL || DEV_TIME_API_URL;
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || RECAPTCHA_TEST_SITE_KEY;

const config = {
  apiUrl: API_URL,
  timeApiUrl: TIME_API_URL,
  googleSearchApiKey: 'AIzaSyDAA2Bx8ejGq0I3NEbzLBHt5BNKJX4yTUA',
  weatherApiUrl: 'https://api.open-meteo.com/v1',
  companiesHouseApiKey: '965ba45e-e7d0-4f5b-a6f5-d26ac7324a8e',
  nojsScraperApiUrl: NOJS_SCRAPERS_API_URL,
  nojsScrapersPassword: NOJS_SCRAPERS_PASSWORD,
  nojsScrapersUser: NOJS_SCRAPERS_USER,
  mapBoxKey:
    'pk.eyJ1Ijoibm1ha3NpbXRzZXYiLCJhIjoiY2t6NWc5aGhnMHE2NTJ1cTc1cXk0YWNiMSJ9.PQ4vmHH97rgg3pfOd7uPLA',
  environment: ENVIRONMENT,
  comingSoon: ENVIRONMENT === ENVIRONMENTS.PROD,
  teamsStripeProductId: 'fc076c29-f96c-4b48-a5a6-3597e8094724',
  payrollStripeProductId: '9927bb1b-6305-44e0-8c66-7ce1233180ad',
  timeStripeProductId: '68d69540-120a-412d-a683-36bb9b9f9059',

  gaMeasurementIdProdLegendApp: 'G-0RFVM0TG8T',
  gaMeasurementIdProdWorkApp: 'G-31DE660J1B',
  recaptchaSiteKey: RECAPTCHA_SITE_KEY,

  docAnalystAssistantId: 'asst_j9Hx7kfiDHHvd7q8TFl4TChZ',
  employerFaqUrl: 'https://vyce-faq.s3.eu-west-1.amazonaws.com/employer_faq.json',
  workerFaqUrl: 'https://vyce-faq.s3.eu-west-1.amazonaws.com/worker_faq.json',
  employerFaqAssistantId: 'asst_DyZTNSqbQCtFtQYsHXdz2S3U',
  workerFaqAssistantId: 'asst_ToLeQ9asHJfqR0uTmutO8Jia',
};

export default config;
